
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  width: 100%;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  @include breakpoint($xl) {
    width: 1200px;
    height: 500px;
  }

  @include breakpoint($md) {
    height: calc(52vw);
  }

  @include breakpoint($sm) {
    height: calc(53vw);
    min-width: 349px;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
}
