
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.weatherElement {
  padding-top: 10px;
  padding-bottom: 20px;

  @include breakpoint($sm) {
    padding-bottom: 15px;
  }

  &:first-child {
    padding-left: 20px;

    @include breakpoint($sm) {
      padding-left: 15px;
    }
  }

  &:last-child {
    padding-right: 20px;

    @include breakpoint($sm) {
      padding-right: 15px;
    }
  }

  @include breakpoint($sm) {
    width: 50%;
  }

  .descriptionWrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    svg {
      flex-shrink: 0;

      path {
        fill: $black-50;
      }
    }
  }

  .weatherElementTitle {
    padding-left: 15px;

    &.weatherElementTitleExtraPadding {
      padding-left: 34px;
    }

    @if mixin-exists(bannerWidgetPrimaryDesktop) { @include bannerWidgetPrimaryDesktop; }

    @include breakpoint($sm) {
      @if mixin-exists(bannerWidgetPrimaryMobile) { @include bannerWidgetPrimaryMobile; }
    }
  }

  .weatherElementTitleTemperature {
    margin-left: 5px;
  }

  .weatherElementSubTitle {
    text-transform: lowercase;
    max-width: 110px;
    margin-left: 10px;

    @if mixin-exists(bannerWidgetSecondaryDesktop) { @include bannerWidgetSecondaryDesktop; }

    @include breakpoint($sm) {
      @if mixin-exists(bannerWidgetSecondaryMobile) { @include bannerWidgetSecondaryMobile; }
    }
  }
}
