
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  will-change: top;
  width: 100%;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  overflow: hidden;

  @include breakpoint($xl) {
    width: 1200px;
    height: 500px;
  }

  @include breakpoint($md) {
    height: calc(52vw);
  }

  @include breakpoint($sm) {
    height: calc(53vw);
  }
}

.title {
  @if mixin-exists(accent1) { @include accent1; }

  color: $white;
  text-transform: uppercase;
  width: 510px;

  @include breakpoint($sm) {
    width: 240px;

    @if mixin-exists(accent3) { @include accent3; }
  }
}

.paragraph {
  @include h7;

  color: $white;
  width: 510px;
  white-space: pre-line;

  @include breakpoint($md) {
    width: 424px;
  }

  @include breakpoint($sm) {
    @include t7;

    width: 235px;
  }
}

.content {
  position: absolute;
  width: 70%;
  top: 100px;
  left: 100px;
  word-break: break-word;
  overflow-wrap: break-word;

  @include breakpoint($md) {
    top: 86px;
    left: 20px;
  }

  @include breakpoint($sm) {
    top: 35px;
    left: 18px;
  }
}
