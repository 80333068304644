
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.widgetElement {
  padding: 10px 20px 20px;
  background-color: $white-80;
  backdrop-filter: blur(2px);

  @include breakpoint($sm) {
    width: 50%;
    padding: 10px;
  }

  .widgetElementTitle {
    @if mixin-exists(bannerWidgetPrimaryDesktop) { @include bannerWidgetPrimaryDesktop; }

    @include breakpoint($sm) {
      @if mixin-exists(bannerWidgetPrimaryMobile) { @include bannerWidgetPrimaryMobile; }
    }
  }

  .widgetElementSubTitle {
    display: flex;
    flex-direction: column;
    text-transform: lowercase;
    min-width: 100px;

    @if mixin-exists(bannerWidgetSecondaryDesktop) { @include bannerWidgetSecondaryDesktop; }

    @include breakpoint($sm) {
      @if mixin-exists(bannerWidgetSecondaryMobile) { @include bannerWidgetSecondaryMobile; }
    }
  }
}

.widgetElementMobile {
  @include h7;

  text-align: center;
  color: $white;
  margin-bottom: 10px;
}
