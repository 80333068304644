
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.text {
  @include t3;

  color: $white;
  margin-top: 5px;
  display: block;

  @include breakpoint($sm) {
    @include t4;

    text-align: center;
  }
}

.highlighted {
  margin-left: 3px;
  text-decoration: underline;
}
