
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.videoWrapper {
  height: 80vh;
  min-height: 694px;

  @include breakpoint($xl) {
    margin-top: -100px;
  }

  @include breakpoint($md-and-sm) {
    margin-top: -90px;
  }

  @include breakpoint($md) {
    min-height: 770px;
  }

  @include breakpoint($sm) {
    min-height: 524px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.videoContentWrap {
  position: absolute;
  height: 80vh;
  min-height: 694px;
  width: 100%;
  left: 0;
  right: 0;

  @include breakpoint($md) {
    min-height: 770px;
  }

  @include breakpoint($sm) {
    min-height: 524px;
  }
}
