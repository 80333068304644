
      @import "regions/kursk/styles/themes/primorsky/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/primorsky/fonts.scss";
      @import "regions/kursk/styles/themes/primorsky/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  will-change: top;
  width: 100%;
}
